import React from 'react';

function Contact() {
    return (
        <div className="text-center">
            <h2>Question or Comments?</h2>
            <p>Please call/text/email us!</p>
            <hr />
            <h3>Phone</h3>
            <p>(715) 459-8500</p>
            <h3>Email</h3>
            <p>cleancar@hutscarwash.com</p>
        </div>
    );
}

export default Contact;