import React from 'react';

function Home() {
    const headerStyle = {
        fontFamily: 'sans-serif'
    };

    return (
        <div className="about-us">
            <h2 className="text-center" style={headerStyle}><b><i>Hut's Car Wash</i></b></h2>
            <hr />
            <div className="page-content">
                <img src="https://i.imgur.com/OBLE0lZ.png" className="carwash-img mb-5 shadow-lg" alt="Hut's Car Wash logo"/>
                <p>Locally owned and operated since 2000. We are proud to serve the greater Wisconsin Rapids area providing an outstanding carwash experience, and always striving for excellence.</p>        
                <p>At Hut’s Car Wash we value our customers, and continuously strive to make sure each time they visit they are 100% satisfied.</p>
                <p>We have many customers who tell us that “Hut’s is the only carwash they use”! 	While we cherish our returning customers we are always happy to serve new ones as well.</p>
                <p>Deeply rooted in the Wisconsin Rapids area, we are proud to support many local schools, groups, and organizations. This is accomplished through proudly participating 
                in SCRIP program with local schools and churches and other fundraising activities.</p>
                <p>At Hut’s we accept cash, MasterCard, Visa, American Express, Discover and tokenotes.</p>
                <br />
                <p className="text-center">Hut’s Car Wash.... <b><i>always</i></b> fast, easy and convenient!</p>
            </div>
        </div>
    );
}

export default Home;