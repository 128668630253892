import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css/animate.css';

import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import NavMenu from './components/navMenu';
import Home from './pages/home';
import Contact from './pages/contact';
import Location from './pages/location';
import Washes from './pages/washes';

function App() {
  return (
    <Router>
      <NavMenu />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/washes" component={Washes} />
        <Route path="/location" component={Location} />
        <Route path="/contact" component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;
