import React from 'react';

function Map() {
    return (
        <div className="animate__animated animate__zoomIn">
            <iframe className="shadow w-75" width="750" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    src="https://www.openstreetmap.org/export/embed.html?bbox=-89.819895029068%2C44.36214747341137%2C-89.81487393379213%2C44.36470550190058&amp;layer=mapnik&amp;marker=44.36342971782161%2C-89.81738448143005"
                    style={{border: "1px solid black"}}></iframe>
            <br />
            <small>
                <a style={{color: "blue"}} href="https://www.openstreetmap.org/?mlat=44.36343&amp;mlon=-89.81738#map=18/44.36343/-89.81738">View Larger Map</a>
            </small>
        </div>
    );
}

export default Map;
