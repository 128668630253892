import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

function NavMenu() {
    return (
        <Navbar className="navbar-dark mb-5" bg="dark" expand="lg">
            <Navbar.Brand href="#/"><img className="hut-sign rounded-lg border border-warning" src="https://i.imgur.com/V85DxMO.jpg" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link className="btn btn-square btn-dark pt-2 pb-2 border-right" href="#/">Home</Nav.Link>
                    <Nav.Link className="btn btn-square btn-dark pt-2 pb-2 border-right" href="#/washes">Washes</Nav.Link>
                    <Nav.Link className="btn btn-square btn-dark pt-2 pb-2 border-right" href="#/location">Where Are We?</Nav.Link>
                    <Nav.Link className="btn btn-square btn-dark pt-2 pb-2 border-right" href="#/contact">Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavMenu;