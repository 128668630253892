import React from 'react';
import WashCard from '../components/washCard';

const washes = [
    {
        name: "Seasonal Wash",
        desc: [
            "Powerful underchassis flush with underbody protectant", 
            "High pressure bug and dirter blaster rinse", 
            "Extra bug and dirt passes on front of vehicle",
            "Double foam action cleaner",
            "High pressure power laser wash",
            "Triple foam polish",
            "Clear coat protectant",
            "Spot-free rinse",
            "Laser dry"
        ],
        price: 13.0,
        seasonal: true
    },
    {
        name: "Ultimate Wash",
        desc: [
            "Under-carriage wash", 
            "Double foam action cleaner", 
            "Double power wash", 
            "Tri-colored foam polish", 
            "Clear coat protectant", 
            "Spot-free rinse & dry"
        ],
        price: 11.0,
        seasonal: false
    },
    {
        name: "Premium Wash",
        desc: [
            "Under-carriage wash", 
            "Double foam action cleaner", 
            "Power wash", 
            "Spot-free rinse & dry"
        ],
        price: 9.0,
        seasonal: false
    }
];

function Washes() {
    washes.forEach((wash, i) => console.log(wash));

    return (
        <div className="mt-5 mb-5">
            { washes.map((wash, i) => 
                <WashCard 
                    washName={wash.name} 
                    washDesc={wash.desc} 
                    washPrice={wash.price} 
                    isSeasonal={wash.seasonal} 
                    key={i} 
                />)}
        </div>
    );
}

export default Washes;