import React from 'react';

function WashCard({washName, washDesc, washPrice, isSeasonal}) {
    const descriptionStyle = {
        fontSize: "1.15em",
        paddingLeft: "8%"
    };

    const leftMargin = {
        marginLeft: "5%"
    };

    const rightMargin = {
        marginRight: "5%"
    };

    return (
        <div className="row w-100 animate__animated animate__fadeInUp">
            <div className="col-md-3"></div>
            <div className="card shadow col-md-6 mt-4 p-3">
                <div className="row">
                    <div className="col-md-5" style={leftMargin}>
                        <h2 className="m-0">{ washName }</h2>
                    </div>
                    <div className="col-md-5 wash-card-price" style={rightMargin}>
                        { "$" + washPrice.toFixed(2) }
                    </div>
                </div>
                {isSeasonal ? <h3 className="text-danger mt-1 mb-0" style={leftMargin}>Hut's Best Value!</h3> : <p></p>}
                <hr />
                <ul style={descriptionStyle}>
                    {washDesc.map((desc, i) => <li key={i} className="m-0">{ desc }</li>)}
                </ul>
            </div>
            <div className="col-md-3"></div>
        </div>
    );
}

export default WashCard;