import React from 'react';
import Map from '../components/map';

function Location() {
    return (
        <div className="page-content mt-5 text-center">
            <h3><b>Where to find us</b></h3>
            <hr className="ml-5 mr-5 dark" />
            <p className="mb-0">3440 8th St S</p>
            <p className="mb-5">Wisconsin Rapids, WI 54494</p>
            <Map />
        </div>
    );
}

export default Location;